<template>
  <div class="login fill-height primary">
    <v-snackbar v-model="showSnackbar" top color="error">
      {{ messageSnackbar }}
    </v-snackbar>
    <v-layout justify-center align-center fill-height class="px-2">
      <v-card class="pb-4 px-3">
        <v-form style="max-width: 320px" @submit.prevent.stop="onSubmit">
          <v-card-title> Login </v-card-title>
          <v-card-subtitle>
            acesso ao backoffice administrativo
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.email"
                  placeholder="E-mail"
                  prepend-icon="mdi-at"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.password"
                  placeholder="Senha"
                  prepend-icon="mdi-key-variant"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
            </v-row>
            <div class="text-right">
              <!--              <v-btn-->
              <!--                  tag="a"-->
              <!--                  plain-->
              <!--                  text-->
              <!--                  :ripple="false"-->
              <!--                  class="-->
              <!--                  pa-0-->
              <!--                  body-2-->
              <!--                  text-lowercase-->
              <!--                  primary&#45;&#45;text"-->
              <!--                  style="height: 0;"-->
              <!--              >-->
              <!--                recuperar senha-->
              <!--              </v-btn>-->
            </div>
          </v-card-text>
          <v-card-actions>
            <v-layout justify-center>
              <v-btn
                v-if="auth.status <= 0"
                class="px-10"
                color="primary"
                type="submit"
              >
                Login
              </v-btn>
              <v-progress-linear v-if="auth.status === 1" indeterminate />
            </v-layout>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
export default {
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    showSnackbar: false,
    messageSnackbar: "",
    showPassword: false,
  }),
  computed: {
    auth() {
      return this.$store.state.auth || {};
    },
  },
  methods: {
    onSubmit() {
      this.$store
        .dispatch("auth/authentication", this.form)
        .then(() => this.$router.push({ name: "Home" }))
        .catch(() => {
          this.alertSnackbar("login inválido");
        });
    },
    alertSnackbar(message) {
      this.showSnackbar = true;
      this.messageSnackbar = message;
    },
  },
};
</script>
